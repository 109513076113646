@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@500&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


body{
  background: #1c0124;
  width: -100%;
  height: 100%;
  margin: 0% !important;
  overflow-x: hidden !important;
  }

.borders{
  border: 2px solid white !important;
}
.loo{
  background: linear-gradient(90deg, #00FFFF 0%, #00F9A9 100%);
}
.kil{
  background: linear-gradient(90deg, #00FFFF 0%, #00F9A9 100%);
}
.jik{
  background: linear-gradient(90deg, #00FFFF 0%, #00F9A9 100%);
}
.container{
  background: #33254C;
}
.dfcv{
  background: #33254C;
}

.custom-border {
  @apply border-2 border-secondary border-opacity-30 rounded-2xl;
}

@layer utilities {
  ::-webkit-scrollbar {
    width: 5px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #00ffee81;
    border-radius: 5px;
  }
}